<template>
  <div>
    <v-row>
      <v-col
        v-for="topic in topics"
        :key="topic.slug"

        cols="12"
        md="6"
        lg="4"
      >
        <legal-card
          :item="topic"
          :is-external-link="topic.isExternalLink"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { appBranding } from '@/lib/features'
import LegalCard from './LegalCard.vue'
import featureMixin from '@/mixins/feature'

const TOPIC = [
  {
    slug: 'imprint',
    title: 'Impressum',
    text: 'Unser gesamtes Impressum haben wir für Sie einfach einsehbar hinterlegt. Der unten platzierte Link führt Sie direkt zum Impressum.',
    route: appBranding.config.imprintUrl,
    target: '_blank',
    linkText: 'Zum Impressum',
    icon: 'mdi-file-document-outline',
    isExternalLink: true
  },
  {
    slug: 'privacy',
    title: 'Datenschutz',
    text: 'Ihre Sicherheit ist uns wichtig. Darum achten wir besonders auf den Umgang mit Ihren Daten. Alles was den Datenschutz angeht finden Sie hier.',
    linkText: 'Zum Datenschutz',
    route: appBranding.config.dataPrivacyUrl,
    target: '_blank',
    icon: 'mdi-security',
    isExternalLink: appBranding.config.dataPrivacyUrl.startsWith('https')
  },
  {
    slug: 'license-agreement',
    title: 'Nutzungs & Lizenzbedingungen',
    text: 'Sehen Sie die Nutzungs & Lizenzbedingungen ein und akzeptieren Sie diese gegebenenfalls.',
    linkText: 'Nutzungs & Lizenzbedingungen',
    route: 'license-agreement',
    icon: 'mdi-file-sign',
    isExternalLink: false
  }

]

export default {
  components: { LegalCard },
  mixins: [featureMixin],

  computed: {
    topics () {
      const isLicenseAgreementFeatureActive = this.isFeatureActive(this.featureNames.LICENSE_AGREEMENT)
      if (!isLicenseAgreementFeatureActive) {
        return TOPIC.filter(topic => topic.slug !== 'license-agreement')
      } else {
        return TOPIC
      }
    }
  }
}
</script>
