
<template>
  <v-card
    outlined
    height="100%"
    class="d-flex flex-column"
  >
    <v-card-title class="my-md-4">
      <h4>
        <v-icon v-if="item.icon">
          {{ item.icon }}
        </v-icon>
        {{ item.title }}
      </h4>
    </v-card-title>

    <v-card-text class="px-6 content text-body-1">
      {{ item.text }}
    </v-card-text>

    <v-row class="mt-2 mr-4 mt-md-6 footer">
      <v-btn
        v-if="isExternalLink"
        :href="item.route"
        :target="item.target"
        class="primary"
      >
        {{ item.linkText }}
        <v-icon
          class="ml-2"
          size="14"
        >
          mdi-open-in-new
        </v-icon>
      </v-btn>
      <v-btn
        v-else
        :to="item.route"
        class="primary"
      >
        {{ item.linkText }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    isExternalLink: {
      type: Boolean,
      default: false
    }
  }

}
</script>

<style scoped>
.content {
  flex: 1 1 auto;
}

.footer {
  flex: 0 1 80px;
  justify-content: flex-end;
}
</style>
